import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, ref } from 'vue';
    import { modalController } from '@ionic/vue';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'ModalCloser',
  setup(__props) {

    const isVisible = ref(false);
    const closeIcon = ref<HTMLDivElement | null>(null);

    const closeModal = () => {
        modalController.dismiss();
    };

    onMounted(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setTimeout(() => {isVisible.value = true;} , 500);
                } else {
                    isVisible.value = false;
                }
            });
        });

        if (closeIcon.value) {
            observer.observe(closeIcon.value);
        }
    });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "closeIcon",
    ref: closeIcon,
    class: _normalizeClass(["close-icon", { 'fade-in': isVisible.value }]),
    onClick: closeModal
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("svg", {
      width: "16",
      height: "16",
      viewBox: "0 0 16 16",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    }, [
      _createElementVNode("path", {
        d: "M13.0306 11.9695C13.1715 12.1104 13.2506 12.3015 13.2506 12.5007C13.2506 12.7 13.1715 12.8911 13.0306 13.032C12.8897 13.1729 12.6986 13.252 12.4993 13.252C12.3001 13.252 12.109 13.1729 11.9681 13.032L7.99997 9.06261L4.0306 13.0307C3.8897 13.1716 3.69861 13.2508 3.49935 13.2508C3.30009 13.2508 3.10899 13.1716 2.9681 13.0307C2.8272 12.8898 2.74805 12.6987 2.74805 12.4995C2.74805 12.3002 2.8272 12.1091 2.9681 11.9682L6.93747 8.00011L2.96935 4.03073C2.82845 3.88984 2.7493 3.69874 2.7493 3.49948C2.7493 3.30023 2.82845 3.10913 2.96935 2.96823C3.11024 2.82734 3.30134 2.74818 3.5006 2.74818C3.69986 2.74818 3.89095 2.82734 4.03185 2.96823L7.99997 6.93761L11.9693 2.96761C12.1102 2.82671 12.3013 2.74756 12.5006 2.74756C12.6999 2.74756 12.891 2.82671 13.0318 2.96761C13.1727 3.10851 13.2519 3.2996 13.2519 3.49886C13.2519 3.69812 13.1727 3.88921 13.0318 4.03011L9.06247 8.00011L13.0306 11.9695Z",
        fill: "black"
      })
    ], -1)
  ]), 2))
}
}

})