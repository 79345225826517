import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/auth-header-image.svg'
import _imports_1 from '@/assets/images/sportspoule-logo.svg'


const _hoisted_1 = { class: "desktop-panel" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { class: "message" }

import { IonButton, IonInput, IonContent, IonPage } from '@ionic/vue';
    import { ref } from 'vue'
    import useAuth from "@/componentables/auth";
    import FloatingBackButton from "@/components/FloatingBackButton.vue";

    
export default /*@__PURE__*/_defineComponent({
  __name: 'AuthForgotPassword',
  setup(__props) {

    const email = ref("");
    const auth = useAuth();
    const emailIsSent = ref(false);

    const handleForgotPassword = () => {
        auth.forgotPassword(email.value).then(() => {
            emailIsSent.value = true;
        });
    }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _cache[2] || (_cache[2] = _createElementVNode("img", {
              alt: "Sportspoule patroon",
              class: "auth-header-image",
              src: _imports_0
            }, null, -1)),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: _normalizeClass([{ hide: emailIsSent.value }, "auth-intro-header"])
              }, [
                _createElementVNode("h1", null, _toDisplayString(_ctx.$t('auth.forgot-password.title')), 1)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass([{ show: emailIsSent.value }, "auth-intro-header email-sent"])
              }, [
                _cache[1] || (_cache[1] = _createElementVNode("img", { src: _imports_1 }, null, -1)),
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('auth.forgot-password.email-sent')), 1)
              ], 2)
            ]),
            _createElementVNode("form", {
              class: _normalizeClass([{ hide: emailIsSent.value }, "auth"]),
              onSubmit: _withModifiers(handleForgotPassword, ["prevent"])
            }, [
              _createVNode(_unref(IonInput), {
                id: "email",
                modelValue: email.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
                name: "email",
                placeholder: _ctx.$t('fields.email'),
                required: ""
              }, null, 8, ["modelValue", "placeholder"]),
              _createVNode(_unref(IonButton), {
                class: "btn",
                expand: "block",
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('auth.forgot-password.cta')), 1)
                ]),
                _: 1
              })
            ], 34)
          ]),
          _createVNode(FloatingBackButton)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})