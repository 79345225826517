import { store } from "@/store";
import Model, { Casts } from "./Model";
import User from "./User";

export default class Group extends Model<Group>() {
    avatar?: string | null;
    users?: User[];
    admins?: any[];
    canInviteMembers?: boolean;
    user_is_current_owner?: boolean;
    deleted_at?: string; // It can be either null or a string
    name?: string;
    poule_id?: number;
    poule_type?: string;
    share_link?: string;
    everyone_can_invite?: boolean;
    everyone_can_rename?: boolean;
    tournament_has_started?: boolean;

    public casts: Casts = {
        users: (attributes) => attributes.users.map((user: object) => new User(user)),
        avatarUrl: (attributes) => attributes.avatar ? `${process.env.VUE_APP_API_URL}/storage/${attributes.avatar}` : null,
    }

    isAdmin(userId: number|null = null): boolean {
        if (!userId) {
            userId = store.getters['profile/user'].id;
        }

        return !!this.admins?.find((user) => user.id === userId);
    }

    canRename(userId: number|null = null): boolean {
        return this.everyone_can_rename || this.isAdmin(userId);
    }

    canInvite(userId: number|null = null): boolean {
        return this.everyone_can_invite || this.isAdmin(userId);
    }
}