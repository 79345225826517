import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/auth-header-image.svg'
import _imports_1 from '@/assets/images/sportspoule-logo.svg'


const _hoisted_1 = { class: "desktop-panel" }
const _hoisted_2 = { class: "auth-intro-header" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "blue" }
const _hoisted_5 = {
  key: 1,
  style: {"margin-bottom":"-100px"}
}

import {
        IonPage,
        IonContent,
    } from '@ionic/vue'
    import OrDivider from "@/components/OrDivider.vue";
    import SignInWithButton from "@/components/SignInWithButton.vue";
    import { useRouter } from 'vue-router';
    import { onMounted, ref } from 'vue';
    import StorageService from '@/services/storage.service';
    import GroupService from '@/services/group.service';
    import { Haptics, ImpactStyle } from "@capacitor/haptics";

    
export default /*@__PURE__*/_defineComponent({
  __name: 'AuthGetStarted',
  setup(__props) {

    const router = useRouter();

    const groupName = ref(null);
    const description = ref<string|null>(null);

    const goToRegister = () => {
        Haptics.impact({ style: ImpactStyle.Medium });
        router.push({ name: 'auth.register' });
    }

    const goToLogin = () => {
        Haptics.impact({ style: ImpactStyle.Medium });
        router.push({ name: 'auth.login' });
    }

    onMounted(async () => {
        if (typeof router.currentRoute.value.query.redirect === 'string') {
            await StorageService.set('intended_url', router.currentRoute.value.query.redirect)
        }
        if (typeof router.currentRoute.value.query.description === 'string') {
            description.value = router.currentRoute.value.query.description;
        }
        const intendedUrl: string = await StorageService.get('intended_url');
        if (intendedUrl?.includes('poules/share')) {
            const groupToken = intendedUrl.split('/').filter(Boolean).pop();
            if (groupToken) {
                groupName.value = await GroupService.getPouleNameByShareLink(groupToken);
            }
        }
    });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _cache[2] || (_cache[2] = _createElementVNode("img", {
              alt: "Sportspoule patroon",
              class: "auth-header-image",
              src: _imports_0
            }, null, -1)),
            _createElementVNode("div", _hoisted_2, [
              _cache[1] || (_cache[1] = _createElementVNode("img", {
                alt: "Sportspoule logo",
                class: "logo",
                src: _imports_1
              }, null, -1)),
              (groupName.value)
                ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                    _createTextVNode(_toDisplayString(_ctx.$t('auth.group-shared-auth.invited-for')) + " ", 1),
                    _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(groupName.value), 1)
                  ]))
                : _createCommentVNode("", true),
              (description.value)
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(description.value), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", {
              class: _normalizeClass([{'less-padding-top': groupName.value}, "actions"])
            }, [
              _createVNode(SignInWithButton, { driver: "apple" }),
              _createVNode(SignInWithButton, { driver: "google" }),
              _createVNode(OrDivider),
              _createElementVNode("div", {
                class: "btn white",
                onClick: goToRegister
              }, _toDisplayString(_ctx.$t('auth.get-started.register')), 1),
              _createElementVNode("div", {
                class: "btn transparant",
                onClick: goToLogin
              }, _toDisplayString(_ctx.$t('auth.get-started.login')), 1)
            ], 2)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})